<template>
  <span>
    <v-card class="mb-0 mt-n2 parametros-v-card">
      <v-row justify="start" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-select
            class="ml-8"
            :items="regionaisList"
            :value="regionalSelecionada"
            @change="setRegionalSelecionada"
            label="Regional selecionado"
          />
        </v-col>
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-autocomplete
            class="ml-5"
            :items="listaMunicipios"
            v-model="municipioSelecionado"
            label="Município selecionado"
            clearable
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row dense justify="center">
      <v-col cols="12" md="9" class="flex-md-grow-1">
        <v-card class="my-1">
          <v-card-text>
            <kpis-by-municipio-data-table
              :traducaoIndicadores="traducaoIndicadores"
              :dados="dadosPorMunicipio"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="auto" class="flex-md-grow-1">
        <v-card class="my-1">
          <v-card-text>
            <kpis-by-municipio-map
              :kpis="kpis"
              :dadosPorMunicipio="dadosPorMunicipio"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="mt-5">
      <v-col v-for="indicador in kpis" :key="indicador" cols="12" md="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>
              {{ traducaoIndicadores[indicador] || indicador.toUpperCase() }}
            </h5>
          </v-card-title>
          <v-card-text>
            <column-chart-mensal
              :dadosMensais="dadosPorCompetencia"
              :categories="categories"
              :indicador="indicador"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  import DashboardNivelTensaoService from "@/services/DashboardNivelTensaoService";
  import MunicipiosService from "@/services/MunicipiosService";

  import { mapState, mapActions } from "vuex";

  export default {
    name: "DashboardNivelTensaoVisaoMunicipios",
    components: {
      KpisByMunicipioDataTable: () =>
        import(
          "@/components/dashboardNivelTensao/KpisByMunicipioDataTable.vue"
        ),
      KpisByMunicipioMap: () =>
        import("@/components/dashboardNivelTensao/KpisByMunicipioMap.vue"),
      ColumnChartMensal: () =>
        import(
          "@/components/dashboardNivelTensao/charts/ColumnChartMensal.vue"
        ),
    },
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      inspecoesSelecionadas: {
        type: Array,
        required: true,
      },
      medicoesSelecionadas: {
        type: Array,
        required: true,
      },
      origemMedicoesSelecionadas: {
        type: Array,
        required: true,
      },
      tensaoReferenciaSelecionadas: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: {},
      },
    },
    data: () => ({
      kpis: [
        "plna",
        "qrt",
        "drpe",
        "drce",
        "prp",
        "psfpt-crp",
        "psfpt-cri",
        "psfpt-rmp",
        "psfpt-rmc",
        "psfpg",
        "icnt",
        "irnt",
        "Total Compensação",
        "QTD Reclamações Totais",
      ],
      regionais: [],
      listaMunicipios: [],
      dadosPorMunicipio: [],
      dadosPorCompetencia: {},
    }),
    computed: {
      ...mapState('parametrosDashboardNivelTensao', [
        'regionalSelecionada',
        'municipio'
      ]),
      municipioSelecionado: {
        get() {
          return this.municipio;
        },
        set(newValue) {
          this.setMunicipio(newValue);
        }
      },
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
    },
    mounted() {
      this.getRegionais();
      this.getDados();
    },
    methods: {
      ...mapActions('parametrosDashboardNivelTensao', [
        'setRegionalSelecionada',
        'setMunicipio'
      ]),
      getRegionais() {
        return MunicipiosService.getRegionais()
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.setRegionalSelecionada(res.find(
                (el) => el.nome.toLowerCase() == "corporativa"
              ).nome);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
      getDados() {
        // Apenas atualizado os dados se for a visao selecionada
        if (this.activatedTab !== 2) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getDadosPorMunicipio(),
          this.getDadosPorCompetencia(),
        ])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      getDadosPorMunicipio() {
        return DashboardNivelTensaoService.getDadosIndicadoresPorMunicipio(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.inspecoesSelecionadas.join(","),
          this.medicoesSelecionadas.join(","),
          this.origemMedicoesSelecionadas.join(","),
          this.tensaoReferenciaSelecionadas.join(","),
        )
          .then((res) => {
            this.dadosPorMunicipio = res;

            this.listaMunicipios = this.dadosPorMunicipio
              .map((el) => el.nome_municipio)
              .sort((a, b) => Number(a ?? 0) - Number(b ?? 0));
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosPorCompetencia() {
        return DashboardNivelTensaoService.getDadosIndicadoresMensais(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.municipioSelecionado,
          this.inspecoesSelecionadas.join(","),
          this.medicoesSelecionadas.join(","),
          this.origemMedicoesSelecionadas.join(","),
          this.tensaoReferenciaSelecionadas.join(","),
        )
          .then((res) => {
            this.dadosPorCompetencia = res;
          })
          .catch((err) => {
            throw err;
          });
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
      regionalSelecionada() {
        this.getDados();
      },
      municipioSelecionado() {
        this.getDadosPorCompetencia();
      },
    },
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
</style>
